.CGR-main-container{
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.CGR-main-header{
    font-size: 1.5rem;
    font-weight: bold;
}

.CGR-tabs{
    margin-top: 1.5rem;
}

.request-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    margin: 8px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.request-bar:hover {
    border-color: #3182ce; /* Blue highlight */
    box-shadow: 2px 2px 12px rgba(49, 130, 206, 0.3);
}

/* Optional: Style the company name inside the bars */
.request-bar span {
    font-size: 1rem;
    font-weight: 500;
    color: #2d3748;
}
