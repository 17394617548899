.main-ordersummary-page-container{
    width: 100%;
    font-family: 'Gowun Batang', serif;
    background-color: rgba(245, 238, 233, 100);
}
.ordersummary-page-container{
    display: flex;
    /* height: 100vh; */
    /* padding: 30px; */
    padding-left: 30px;
    padding-right: 30px;
}

.inner-container{
    display: flex;
    flex-direction: column;
    height: auto;
    flex:1;
    margin-top: 20vh;
    border: 1px solid black;
    border-radius: 20px;
    padding: 30px;
}
.ic-header{
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  position: absolute;
  left: 42%;
  
}

.back-icon{
    height: 30px;
    width: 30px;
}

.container-header-os{
    left-padding: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    margin-top: 0.5rem;
    letter-spacing: 1px;
}

.delivery-details-area {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 60vw;
  }
  
  .row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .phno-container{
    margin-top: 20px;
  }
  .input-container {
    position: relative;
    flex: 1;
    border: 1.6px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  
  .name-inputs-container {
    display: flex;
    gap: 20px;
  }
  
  .name-input,
  select {
    background-color: transparent;
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 8px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  select:focus {
    border-color: rgba(0, 0, 0, 0.7);
  }
  
  select option:disabled {
    display: none;
  }
  
  .text-area-container {
    width: 100%;
    position: relative;
  }
  
  .address-textarea {
    width: 100%;
    height: 60px;
    background-color: transparent;
    border: 1.6px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 16px;
    padding: 15px 15px 10px;
    outline: none;
    resize: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .address-textarea::placeholder {
    color: transparent;
  }
  
  .placeholder-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    transition: 0.3s ease-in-out;
  }

  .name-input:focus + .placeholder-label,
  .name-input:not(:placeholder-shown) + .placeholder-label,
  .address-textarea:focus + .placeholder-label,
  .address-textarea:not(:placeholder-shown) + .placeholder-label,
  select:focus + .placeholder-label {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
  }

  .phno-container {
    position: relative;
    width: 100%; /* Adjust width as needed */
    max-width: 400px;
  }

  .phno-prefix {
    background-color: #f5f5f5; /* Light gray background for prefix */
    color: rgba(0, 0, 0, 0.7);
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 100%;
    white-space: nowrap;
  }
  
  .divider-line {
    width: 1.5px;
    background-color: rgba(0, 0, 0, 0.5);
    height: 60%;
    margin-left: -1px; /* Align the line with the border */
  }

  .phno-input {
    flex: 1;
    border: none;
    outline: none;
    height: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: transparent;
  }
  
  .phno-input::placeholder {
    color: transparent;
  }
  
  .placeholder-label1 {
    position: absolute;
    top: 50%;
    left: 50px; 
    transform: translateY(-50%);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    transition: 0.3s ease-in-out;
  }
  
  .phno-input:focus + .placeholder-label1,
  .phno-input:not(:placeholder-shown) + .placeholder-label1 {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
  }

  .input-container1 {
  display: flex;
  align-items: center;
  border: 1.6px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  height: 40px;
  overflow: hidden;
}
  
.details-bar {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 10px 20px;
  background-color: #fff;
  border: 1.6px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 20px;
  max-height: 6rem;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.details-bar.expanded{
  max-height: 580px;
}
.details-bar.collapsing {
  max-height: 6rem; 
}


.bar-buttons {
  display: flex;
  align-items:center;
  gap: 10px;
}

.edit-button,
.save-button {
  background-color:rgba(50, 0, 1, 0.9);
  color: white;
  border: none;
  border-radius: 5px;
  /* padding: 5px 15px; */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 5rem;
  height: 3rem;
  border: 1px solid black;
}

.save-button:hover,
.edit-button:hover{
  background-color: transparent;
  color: black;
}


.edit-button.disabled{
  display: none;
}
.details-bar-area{
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}


.ud-user-data{
  display: flex;
  flex-direction: column;
  text-align: left;
}


.confirmandpay{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:1rem;
  margin-top: 3rem;
}
.modal-footer-submit,
.modal-total-summary,
.modal-order-summary,
.modal-delivery-details{
  font-family:'Gowun Batang', serif;
}

.modal-delivery-details{
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  font-family:'Gowun Batang', serif;
}

/* Delivery Info Section */
.modal-delivery-info {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1.4;
  font-family:'Gowun Batang', serif;
}

/* Product Summary Section */
.modal-products-info {
  margin-bottom: 1.5rem;
}
.products-summary-header {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family:'Gowun Batang', serif;
}
.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}
.product-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
}
.product-details {
  margin-left: 10px;
}
.product-name {
  font-size: 1.2rem;
  font-weight: bold;
  font-family:'Gowun Batang', serif;
}
.product-pricing {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: #757575;
  font-family:'Gowun Batang', serif;
}
.discounted-price {
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  font-family:'Gowun Batang', serif;
}
.original-price {
  text-decoration: line-through;
  color: #9e9e9e;
}
.product-discount {
  color: #4caf50;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Price Summary Section */
.modal-price-summary {
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
  font-family:'Gowun Batang', serif;
}
.price-summary-header {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.summary-row {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.summary-divider {
  border: none;
  border-top: 1px dashed #e0e0e0;
  margin: 0.5rem 0;
}
.summary-row.total {
  font-size: 1rem;
  font-weight: bold;
}

.product-quantity{
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Gowun Batang', serif;
}
/* style={{width:'5vw', padding:'5px', backgroundColor: 'rgba(245, 238, 233, 100)' }} */
.back-icon-button{
  width: 5vw;
  padding: 5px;
  background-color: rgba(245, 238, 233, 100);
}

.final-modal-content{
  max-height: 80vh;
  overflow-y: auto;
  max-width: 35vw;

}


@media only screen and (max-width: 768px){

  .main-ordersummary-page-container {

    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
 
  }

  .inner-container {
    padding: 0 !important;
    /* margin: 0 !important; */
    border-radius: 0 !important;
    border: none !important;
    margin-top: 9rem;
  }

  .ic-header{
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 0.5rem; 
    position: static !important;
  }
  
  .back-icon-button, .back-icon {
    display: none !important;
  }
  
  .container-header-os{
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 1px;

  }
  
  .delivery-details-area {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 100%;
      
  }
    
    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    
    .phno-container{
      margin-top: 20px;
    }
    .input-container {
      position: relative;
      flex: 1;
      border: 1.6px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      width: 100%;
    }
    
    .name-inputs-container {
      display: flex;
      flex-direction:column;
      gap: 20px;
    }
    
    .name-input,
    select {
      background-color: transparent;
      width: 100%;
      height: 40px;
      font-size: 16px;
      padding: 8px;
      outline: none;
      transition: border-color 0.3s ease-in-out;
    }
    
    select:focus {
      border-color: rgba(0, 0, 0, 0.7);
    }
    
    select option:disabled {
      display: none;
    }
    
    .text-area-container {
      width: 100%;
      position: relative;
    }
    
    .address-textarea {
      width: 100%;
      height: 60px;
      background-color: transparent;
      border: 1.6px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      font-size: 16px;
      padding: 15px 15px 10px;
      outline: none;
      resize: none;
      transition: border-color 0.3s ease-in-out;
    }
    
    .address-textarea::placeholder {
      color: transparent;
    }
    
    .placeholder-label {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      transition: 0.3s ease-in-out;
    }
  
    .name-input:focus + .placeholder-label,
    .name-input:not(:placeholder-shown) + .placeholder-label,
    .address-textarea:focus + .placeholder-label,
    .address-textarea:not(:placeholder-shown) + .placeholder-label,
    select:focus + .placeholder-label {
      top: -10px;
      left: 10px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.7);
    }
  
    .phno-container {
      position: relative;
      width: 100%; /* Adjust width as needed */
      max-width: 400px;
    }
  
    .phno-prefix {
      background-color: #f5f5f5; /* Light gray background for prefix */
      color: rgba(0, 0, 0, 0.7);
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-size: 16px;
      height: 100%;
      white-space: nowrap;
    }
    
    .divider-line {
      width: 1.5px;
      background-color: rgba(0, 0, 0, 0.5);
      height: 60%;
      margin-left: -1px; /* Align the line with the border */
    }
  
    .phno-input {
      flex: 1;
      border: none;
      outline: none;
      height: 100%;
      padding: 0 10px;
      font-size: 16px;
      background-color: transparent;
    }
    
    .phno-input::placeholder {
      color: transparent;
    }
    
    .placeholder-label1 {
      position: absolute;
      top: 50%;
      left: 50px; 
      transform: translateY(-50%);
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      transition: 0.3s ease-in-out;
    }
    
    .phno-input:focus + .placeholder-label1,
    .phno-input:not(:placeholder-shown) + .placeholder-label1 {
      top: -10px;
      left: 10px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.7);
    }
  
    .input-container1 {
    display: flex;
    align-items: center;
    border: 1.6px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    height: 40px;
    overflow: hidden;
  }
    
  .details-bar {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 10px 20px;
    background-color: #fff;
    border: 1.6px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin-bottom: 20px;
    /* height: 8rem; */
    max-height: 8rem;
    transition: max-height 0.5s ease-in-out;
    overflow: auto;
    max-width: 85vw;
  }
  
  .details-bar.expanded{
    max-height: 800px;
    max-width: 85vw;
  }
  .details-bar.collapsing {
    height: auto; 
    max-width: 85vw;
  }
  
  
  .bar-buttons {
    display: flex;
    align-items:center;
    gap: 10px;
  }
  
  .edit-button,
  .save-button {
    background-color:rgba(50, 0, 1, 0.9);
    color: white;
    border: none;
    border-radius: 5px;
    /* padding: 5px 15px; */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 5rem;
    height: 3rem;
    border: 1px solid black;
  }
  
  .save-button:hover,
  .edit-button:hover{
    background-color: transparent;
    color: black;
  }
  
  
  .edit-button.disabled{
    display: none;
  }
  .details-bar-area{
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  
  .ud-user-data{
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  
  .confirmandpay{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    margin-top: 3rem;
  }
  .modal-footer-submit,
  .modal-total-summary,
  .modal-order-summary,
  .modal-delivery-details{
    font-family:'Gowun Batang', serif;
  }
  
  .modal-delivery-details{
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    font-family:'Gowun Batang', serif;
  }
  
  /* Delivery Info Section */
  .modal-delivery-info {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    line-height: 1.4;
    font-family:'Gowun Batang', serif;
  }
  
  /* Product Summary Section */
  .modal-products-info {
    margin-bottom: 1.5rem;
  }
  .products-summary-header {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family:'Gowun Batang', serif;
  }
  .product-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
  }
  .product-image img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
  }
  .product-details {
    margin-left: 10px;
  }
  .product-name {
    font-size: 1.2rem;
    font-weight: bold;
    font-family:'Gowun Batang', serif;
  }
  .product-pricing {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    color: #757575;
    font-family:'Gowun Batang', serif;
  }
  .discounted-price {
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    font-family:'Gowun Batang', serif;
  }
  .original-price {
    text-decoration: line-through;
    color: #9e9e9e;
  }
  .product-discount {
    color: #4caf50;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  /* Price Summary Section */
  .modal-price-summary {
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
    font-family:'Gowun Batang', serif;
  }
  .price-summary-header {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .summary-row {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .summary-divider {
    border: none;
    border-top: 1px dashed #e0e0e0;
    margin: 0.5rem 0;
  }
  .summary-row.total {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .product-quantity{
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Gowun Batang', serif;
  }

  .warning-text{
    max-width: 90%;
  }

  .user-details{
    max-width: 90%;
    padding-bottom: 20px;
  }
  .rest-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .user-details-text{
    height: auto;
  }

  .final-modal-content{
    max-height: 90vh;
    overflow-y: auto;
    max-width: 90%;
  
  }
}





