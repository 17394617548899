.pricing-summary-main-container {
    display: flex;
    flex-direction: column; /* Changed to column for vertical layout */
    /* Added border for visual separation */
    justify-content:center;
    align-items:center;
    margin-top: 2rem; /* Added rounded corners */
  }
  
  .pricing-summary-header {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  
  .pricing-summary-details {
    padding: 1rem; /* Added padding for better spacing */
    border: 1.6px solid rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 50%;
    justify-content:center;
  }
  
  .pricing-summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  
  .total {
    font-weight: bold;
  }

  .divider-liner {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.5); 
  }

  @media only screen and (max-width: 768px){
    .pricing-summary-main-container {
      display: flex;
      flex-direction: column; /* Changed to column for vertical layout */
      /* Added border for visual separation */
      justify-content:center;
      align-items:flex-start;
      margin-top: 2rem; /* Added rounded corners */
      
    }
    
    .pricing-summary-header {
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 1rem;
      text-align: left;
    }
    
    .pricing-summary-details {
      padding: 1rem; /* Added padding for better spacing */
      border: 1.6px solid rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      width: 100%;
      justify-content:center;
    }
    
    .pricing-summary-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    
    
    .total {
      font-weight: bold;
    }
  
    .divider-liner {
      width: 100%;
      border: 2px solid rgba(0, 0, 0, 0.5); 
    }
  }