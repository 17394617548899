.product-summary-container{
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap:1rem;
}

.product-summary-header{
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.ps-inner-container{
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(0,0,0,0.5);
    border-radius: 20px;
    width: 30vw;
}

.ps-summary-product{
    display: flex;
    margin-top: 1rem;
    gap: 1.2rem;

}

.ps-summary-product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    /* background-color: red; */
    overflow: hidden; 
    
}

#ps-product-img {
    /* max-width: 100%;  */
    /* height: auto; */
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 5px;
    /* height: 100%;
    width: 85%; */
}
.ps-summary-product-info{
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
}
.ps-summary-product-name{
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    
}

.ps-summary-product-quantity {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.quantity-btn {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.quantity-btn:disabled {
    background-color: #eaeaea;
    cursor: not-allowed;
}

.quantity-btn:hover:not(:disabled) {
    background-color: #ddd;
}

.quantity-input {
    width: 40px;
    height: 30px;
    text-align: center !important;
    font-size: 16px !important;
    line-height: 30px !important; 
    border: 1px solid #ccc !important; 
    background-color: transparent; 
    border-radius: 4px; 
    box-sizing: border-box; 
    outline: none; 
}

.stock-warning {
    margin-top: 5px;
    color: red;
    font-size: 0.9rem;
}
.ps-summary-product-pricing{
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
}

@media only screen and (max-width: 768px){
    .product-summary-container{
        display: flex;
        margin-top: 1rem;
        flex-direction: column;
        gap:1rem;
    }
    
    .product-summary-header{
        text-align: left;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 1px;
    }
    
    .ps-inner-container{
        display: flex;
        flex-direction: row;
        border: 1px solid rgb(0,0,0,0.5);
        border-radius: 20px;
      
        width: 100%;
        height: 13rem;
    }
    
    .ps-summary-product{
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        gap: 0.5rem;
       
    }
    
    .ps-summary-product-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        /* background-color: red; */
        overflow: hidden; 
        
    }
    
    #ps-product-img {
        /* max-width: 100%;  */
        /* height: auto; */
        width: 160px;
        height: 160px;
        object-fit: cover;
        border-radius: 5px;
        /* height: 100%;
        width: 85%; */
    }
    .ps-summary-product-info{
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
    .ps-summary-product-name{
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 1px;
        
    }
    
    .ps-summary-product-quantity {
        margin-top: 10px;
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    
    .quantity-controls {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 5px;
    }
    
    .quantity-btn {
        width: 30px;
        height: 30px;
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.2s;
    }
    
    .quantity-btn:disabled {
        background-color: #eaeaea;
        cursor: not-allowed;
    }
    
    .quantity-btn:hover:not(:disabled) {
        background-color: #ddd;
    }
    
    .quantity-input {
        width: 40px;
        height: 30px;
        text-align: center !important;
        font-size: 16px !important;
        line-height: 30px !important; 
        border: 1px solid #ccc !important; 
        background-color: transparent; 
        border-radius: 4px; 
        box-sizing: border-box; 
        outline: none; 
    }
    
    .stock-warning {
        margin-top: 5px;
        color: red;
        font-size: 0.9rem;
    }
    .ps-summary-product-pricing{
        display:flex;
        justify-content: flex-start;
        flex-direction: column;
    }
}
